<template>
  <Cinemashle msg="Guess which two movies were combined to generate these images"/>
</template>

<script>
  import Cinemashle from './components/FrameMix.vue'
  export default {
  name: 'App',
  components: {
  Cinemashle
  }
  }
</script>

<style>
  #app {
  @import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');
  font-family: Avenir, Helvetica, Arial, sans-serif, 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  }
</style>
