<template>
  <div v-show="showTutorial" class="tutorial">
    <h3>How to play<button style="float:right;margin-right:0px;background-color:black;" class="regularButton" @click="doHideTutorial">✖️</button>
  </h3>
    <div style="margin-bottom: 10px;">Cinemashle is a daily movie guessing game.  Frames from two movies are combined in an AI art generator to create the image clues.</div>
    <div style="margin-bottom: 10px;">Let's combine these frames from Clueless and Robocop to create a new image</div>
    <div>
      <img src="/img/sample1.jpg"/>
      <img src="/img/sample3.jpg"/>   </div>
    <div style="margin-bottom: 10px;">Notice the yellow jacket from Cher and the gray sci-fi outfit on the guy.  He doesn't look anything like Robocop, but other image clues would feature other aspects of the movies</div>
    <div style="margin-bottom: 10px;">Sometimes the combinations merge the two scenes and sometimes they pick a characters from one scene and drop them into a background from the other.</div>
    <div>
      <button style="padding-left:10px;padding-right:10px;margin-bottom: 10px;" class="regularButton" @click="doHideTutorial">I don't need to read more.  Let's Play</button>
    </div>
    <div style="margin-bottom: 10px;">You have 7 guesses to get both movies right</div>
    <div style="margin-bottom: 10px;">Leave one or both guess input fields empty to skip to the next clue</div>
    <div style="margin-bottom: 10px;">Correct answers will match on either side</div>
    <div style="margin-bottom: 10px;">Sequels: Most movies with sequels are considered one mega-movie in Cinemashle and the answer will be the original title.  Text hints will only reference the original film.  Exceptions are made for sequels with distinct characters or visual styles.</div>
    <div style="margin-bottom: 10px;">Remakes: Similar to sequels, movies with remakes will feature mashed images from any version.  Text hints for remakes include information for all versions.</div>
    <div>
      <button style="padding-left:10px;padding-right:10px;" class="regularButton" @click="doHideTutorial">I read more.  Let's Play</button>
    </div>
  </div>
  <div v-show="showGame">

    <div class="topBar">
      <div></div>
      <div class="dropdown">
        <select class="dropbtn" @change="gotoSelectedMash($event)">
          <option class="dropdown-content" href="#">Archive</option>
          <option class="dropdown-content" href="#" v-for="n in maxMashNum" :value="n">
            #{{n}} {{mashProgress[n] == 'p' ? pencil : ''}}{{mashProgress[n] == 'c' ? checkmark : ''}}
          </option>
        </select>
      </div>
      <div>
        <span class="alternativeh4" style="font-weight:900;" href="#" @click="displayAbout">About</span>
        <span class="alternativeh4" style="font-weight:900; margin-left:40px;" href="#" @click="doShowTutorial">How To Play</span>
      </div>
      <div></div>
      <div></div>
    </div>
    <div class="topFrame">
      <div class="about" v-show="showAbout">
        <button style="float:right;margin-right:0px;background-color:black;" class="regularButton" @click="hideAbout">✖️</button>
        <div class="aboutText">Cinemashle is a daily game to guess which two movies have been mashed together to create the images in the clues.</div>
        <div class="aboutText">You have 7 guesses to get both movies right.  With each guess starting with #3 a text clue will be revealed.  </div>
        <div class="aboutText">Sequels: Most movies with sequels are considered one mega-movie in Cinemashle and the answer will be the original title.  Text hints will only reference the original film.  Exceptions are made for sequels with distinct characters or visual styles.</div>
        <div class="aboutText">Remakes: Similar to sequels, movies with remakes will feature mashed images from any version.  Text hints for remakes include information for all versions.</div>
        <div class="aboutText">Copyrights for seed images property of their respective owners</div>
        <div class="aboutText">Contacts</div>
        <div style="float:left" class="aboutText">
          Twitter <a href="https://twitter.com/cinemashle">@cinemashle</a>
        </div>
        <div style="float:right;margin-right: 3px;" class="aboutText">
          <a href="{{mailgen}}">email</a>
        </div>

      </div>

      <div class="slideshow-container">

        <div v-show="movieScript" class="slideshow-container">
          <button style="float:right;margin-right:0px;background-color:black;" class="regularButton" @click="hideScript">✖️</button>
          <iframe width="800" height="400" :src="`/script/${framePath}/${daily['script'+this.mashNum]}`"></iframe>
          <div>
            <button class="regularButton" @click="hideScript">Close Script</button>
          </div>
        </div>
        
        <div v-show="!movieScript">
          <div v-show="show1" class="imgbox fade">

            <img class="center-fit" :src="require(`@/assets/frames/${mashNum}/1.jpg`)">
              <div class="mashNumInset">Cinemashle #{{mashNum}}</div>
              <div class="text">
                <a class="prev" @click="prevSlide" v-show="prevShow">&#10094;</a>Image Clue 1<a class="next" @click="nextSlide" v-show="nextShow">&#10095;</a>
              </div>
            </div>
          <div v-show="show2" class="imgbox fade">
            <img class="center-fit" :src="require(`@/assets/frames/${mashNum}/2.jpg`)">
              <div class="mashNumInset">Cinemashle #{{mashNum}}</div>
              <div class="text">
                <a class="prev" @click="prevSlide" v-show="prevShow">&#10094;</a>Image Clue 2<a class="next" @click="nextSlide" v-show="nextShow">&#10095;</a>
              </div>
            </div>
          <div v-show="show3" class="imgbox fade">
            <img class="center-fit" :src="require(`@/assets/frames/${mashNum}/3.jpg`)">
              <div class="mashNumInset">Cinemashle #{{mashNum}}</div>
              <div class="text">
                <a class="prev" @click="prevSlide" v-show="prevShow">&#10094;</a>Image Clue 3<a class="next" @click="nextSlide" v-show="nextShow">&#10095;</a>
              </div>
            </div>
          <div v-show="show4" class="imgbox fade">
            <img class="center-fit" :src="require(`@/assets/frames/${mashNum}/4.jpg`)">
              <div class="mashNumInset">Cinemashle #{{mashNum}}</div>
              <div class="text">
                <a class="prev" @click="prevSlide" v-show="prevShow">&#10094;</a>Image Clue 4<a class="next" @click="nextSlide" v-show="nextShow">&#10095;</a>
              </div>
            </div>
          <div v-show="show5" class="imgbox fade">
            <img class="center-fit" :src="require(`@/assets/frames/${mashNum}/5.jpg`)">
              <div class="mashNumInset">Cinemashle #{{mashNum}}</div>
              <div class="text">
                <a class="prev" @click="prevSlide" v-show="prevShow">&#10094;</a>Image Clue 5<a class="next" @click="nextSlide" v-show="nextShow">&#10095;</a>
              </div>
            </div>
          <div v-show="show6" class="imgbox fade">
            <img class="center-fit" :src="require(`@/assets/frames/${mashNum}/6.jpg`)">
              <div class="mashNumInset">Cinemashle #{{mashNum}}</div>
              <div class="text">
                <a class="prev" @click="prevSlide" v-show="prevShow">&#10094;</a>Image Clue 6<a class="next" @click="nextSlide" v-show="nextShow">&#10095;</a>
              </div>
            </div>
          <div v-show="show7" class="imgbox fade">
            <img class="center-fit" :src="require(`@/assets/frames/${mashNum}/7.jpg`)">
              <div class="mashNumInset">Cinemashle #{{mashNum}}</div>
              <div class="text">
                <a class="prev" @click="prevSlide" v-show="prevShow">&#10094;</a>Image Clue 7<a class="next" @click="nextSlide" v-show="nextShow">&#10095;</a>
              </div>
            </div>
          <div>
            <button v-show="correctModal || loseModal" class="regularButton" @click="showScript">Show Script</button>
          </div>
        </div>
      </div>
      <div v-show="false && showTextHints && !correctModal && !loseModal">
        <div class="alternativeh4">You may select one text hint per guess</div>
        <button v-show="showYearHintButton" class="regularButton" @click="showYearHint">Decade of release</button>
        <button v-show="showDirectorHintButton" class="regularButton" @click="showDirectorHint">Director</button>
        <button v-show="showActorHintButton" class="regularButton" @click="showActorHint">Actor</button>
      </div>
      <div>
        <div class="inputSection" v-show="inputSectionShow">
          <form action="javascript:void(0);">

            <div class="m1Input" v-show="m1Correct">
              <div class="alternativeh4">Movie 1 correct:</div>
              <div class="alternativeh4">{{m1CorrectValue}}</div>
            </div>
            <div class="m1Input" v-show="m1InputShow">
              <div class="alternativeh4">Movie 1</div>

              <datalist id="movie1">
                <option v-for ="movie in movieData" :key="movie.mName">
                  {{ movie.mName }}
                </option>
              </datalist>
              <input @click="clearIn1" v-model="movie1Input" autoComplete="on" list="movie1" size="25"/>
              <div v-show="yearHint1">
                <img title="Decade released" src="/img/icon_calendar.png"/>{{yearHint1}}
              </div>
              <div v-show="directorHint1">
                <img title="Director" src="/img/icon_slate.png"/>{{directorHint1}}
              </div>
              <div v-show="actorHint1_0">
                <img title="Actor" src="/img/icon_actor.png"/>{{actorHint1_0}}
              </div>
              <div v-show="actorHint1_1">
                <img title="Actor" src="/img/icon_actor.png"/>{{actorHint1_1}}
              </div>
              <div v-show="actorHint1_2">
                <img title="Actor" src="/img/icon_actor.png"/>{{actorHint1_2}}
              </div>
            </div>
            <div class="m2Input" v-show="m2Correct">
              <div class="alternativeh4">Movie 2 correct:</div>
              <div class="alternativeh4">{{m2CorrectValue}}</div>
            </div>
            <div class="m2Input" v-show="m2InputShow">
              <div  class="alternativeh4">Movie 2</div>

              <datalist id="movie2">
                <option v-for ="movie in movieData" :key="movie.mName">
                  {{ movie.mName }}
                </option>
              </datalist>
              <input @click="clearIn2" v-model="movie2Input" autoComplete="on" list="movie2" size="25"/>
              <div v-show="yearHint2">
                <img title="Decade released" src="/img/icon_calendar.png"/>{{yearHint2}}
              </div>
              <div v-show="directorHint2">
                <img title="Director" src="/img/icon_slate.png"/>{{directorHint2}}
              </div>
              <div v-show="actorHint2_0">
                <img title="Actor" src="/img/icon_actor.png"/>{{actorHint2_0}}
              </div>
              <div v-show="actorHint2_1">
                <img title="Actor" src="/img/icon_actor.png"/>{{actorHint2_1}}
              </div>
              <div v-show="actorHint2_2">
                <img title="Actor" src="/img/icon_actor.png"/>{{actorHint2_2}}
              </div>
            </div>
            <div class="checkAnswers">
              <button class="regularButton" @click="checkAnswers">Check Answers / Show Next Image Clue</button>
            </div>
          </form>
          <div>{{guessesRemaining}} guesses remaining</div>
          <div v-for="(item, i) in m1Guesses" v-show="m1Guesses[i]">
            <span class="guesses">{{ item }}</span>
            <span class="guesses">{{m2Guesses[i]}}</span>
          </div>
        </div>

      </div>
    </div>
    <div class="correctModal" v-show="correctModal">
      <h2>You got '{{daily[this.mashNum][0]['movie']}}' and '{{daily[this.mashNum][1]['movie']}}' in {{currGuess}}</h2>
    </div>
    <div v-show="loseModal">
      <h2>The answers were '{{daily[this.mashNum][0]['movie']}}' and '{{daily[this.mashNum][1]['movie']}}'</h2>
    </div>
    <div v-show="correctModal || loseModal">
      <div>Cinemashle #{{mashNum}}</div>
      <div>{{badgeL1}}</div>
      <div>{{badgeL2}}</div>
      <div>
        <button v-show="canCopy()" class="shareButton" @click="copyBadge">{{shareText}}</button>
      </div>
      <div class="alternativeh3">
        <button v-show="earlierMashExists()" class="regularButton" @click="showPrevMash">Try the previous mash</button>
      </div>
    </div>
  </div>

  <!--<div>Currguess {{currGuess}} and ic {{daily['ic'+this.mashNum]}}</div>-->
</template>

<script>
  import movieData from './movies.js'
  import daily from './daily.js'

  function httpGetAsync(theUrl, callback) {
  let xmlHttpReq = new XMLHttpRequest();
  xmlHttpReq.onreadystatechange = function () {
  if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 200) callback(xmlHttpReq.responseText);
  };
  xmlHttpReq.open("GET", theUrl, true); // true for asynchronous
  xmlHttpReq.send(null);
  }

//abba: [{movie:'', alternateNames: [''], year: '', director: '', billing: ['','', '']}, {movie:'', alternateNames: [],  director: '', year: '', billing: ['','','']}],
//icabba: 7,
//scriptabba: '',
  //write a lengthy multipart script that is a mashup of the movies "" and ""
  //write a lengthy script that is a mashup between the movies "" and ""

  export default {
  name: 'Cinemashle',
  props: {
  msg: String
  },
  data() {
  return { maxMashNum: 274, mashNum: 1, movieData: movieData, daily: daily, mashProgress: {},
  show1: true, show2: false, show3: false, show4: false, show5: false, show6: false, show7: false, currGuess: 1, guessesRemaining: 7,
  movie1Input: '',movie2Input: '', m2Correct: false, m1Correct: false, m1CorrectValue: 'Not yet answered',m2CorrectValue: 'Not yet answered',
  m1InputShow: true, m2InputShow: true, correctModal: false, loseModal: false, prevShow: false, nextShow: false,
  m1Guesses: [],
  m2Guesses: [],
  red: '🟥', green: '🟩', yellow: '🟨', black: '⬛', checkmark: '✔️', pencil: '✏️',
  badgeL1: 'Movie 1 ', badgeL2: 'Movie 2', inputSectionShow: true,
  showTextHints: false, swapHints: false, answeredMovieIdx: -1,
  yearHint1: false, directorHint1: false, actorHint1_0: false, actorHint1_1: false, actorHint1_2: false, actorHint1Num: 0,
  yearHint2: false, directorHint2: false, actorHint2_0: false, actorHint2_1: false, actorHint2_2: false, actorHint2Num: 0,
  showYearHintButton: true ,showDirectorHintButton: true,showActorHintButton: true,
  shareText: 'SHARE', showAbout: false, mailgen: '', mtdomain: 'cinemashle.com', movieScript: false, framePath: '',
  guessMetricsA: 'ph/1.gif', guessMetricsB: 'ph/1.gif', guessMetricsAnswerA: 'ph/1.gif', guessMetricsAnswerB: 'ph/1.gif', showTutorial: false, showGame: true
  }
  },

  beforeMount() {
  this.fromStorage();
  this.checkTutorialShow();
  this.mashNum = this.maxMashNum;
  this.gotoMash(this.mashNum);
  this.calcHintShows();
  },

  methods: {
  getRandomInt(max) {
  return Math.floor(Math.random() * max);
  },

  doShowTutorial() {
  this.showTutorial = true;
  this.showGame = false;
  },

  doHideTutorial() {
  this.showTutorial = false;
  this.showGame = true;
  },

  checkTutorialShow() {
  try {
  let key = 'cinemashleMisc';
  let storageMap = {};
  if (window.localStorage.getItem(key) == null) {
  } else {
  storageMap = JSON.parse(window.localStorage.getItem(key));
  }
  if(! storageMap.hasOwnProperty('showTutorial')) {
  this.doShowTutorial();
  storageMap['showTutorial'] = false;
  window.localStorage.setItem(key, JSON.stringify(storageMap));
  }
  } catch(e) {}
  },

  showPrevMash() {
  if(this.mashNum == 1) {
  return;
  }
  this.gotoMash(this.mashNum-1);
  },

  scriptPath() {
  if(!this.correctModal && !this.loseModal) {
  this.framePath = 'view';
  } else if(this.correctModal) {
  this.framePath = 'true';
  } else if(this.loseModal) {
  this.framePath = 'false';
}
  },

  gotoSelectedMash(event) {
  if(event && event.target && event.target.value && !isNaN(Number(event.target.value)) ) {
  this.gotoMash(event.target.value);
  }
  },

  gotoMash(num) {
  this.movieScript = false;
  this.disp1();
  this.currGuess = 1;
  this.guessesRemaining = 7;
  this.mashNum = num;
  this.m1InputShow = true;
  this.m2InputShow = true;
  this.m1Correct = false;
  this.m2Correct = false;
  this.clearIn1();
  this.clearIn2();
  this.correctModal = false;
  this.loseModal = false;
  this.scriptPath();
  this.inputSectionShow = true;
  this.prevShow = false;
  this.nextShow = false;
  this.badgeL1 = 'Movie 1 ';
  this.badgeL2 = 'Movie 2 ';
  this.showTextHints= false;
  this.swapHints= false;
  this.yearHint1= false;
  this.directorHint1= false;
  this.actorHint1_0= false;
  this.actorHint1_1= false;
  this.actorHint1_2= false;
  this.actorHint1Num= 0;
  this.yearHint2= false;
  this.directorHint2= false;
  this.actorHint2_0= false;
  this.actorHint2_1= false;
  this.actorHint2_2= false;
  this.actorHint2Num= 0;
  this.answeredMovieIdx = -1;
  this.m1Guesses = [];
  this.m2Guesses = [];
  this.movie1Input = '';
  this.movie2Input = '';
  this.show1 = true;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;
  this.fromStorage();
  this.shareText = 'SHARE';
  this.mashProgress = this.getGlobalMashProgress();
  },

  toStorage() {
  try {
  let storageMap = {};
  let key = 'cinemashle'+this.mashNum;
  if (window.localStorage.getItem(key) !== null) {
  storageMap = JSON.parse(window.localStorage.getItem(key));
  }
  storageMap['currGuess'] = this.currGuess;
  storageMap['guessesRemaining'] = this.guessesRemaining;
  storageMap['mashNum'] = this.mashNum;
  storageMap['m1InputShow'] = this.m1InputShow;
  storageMap['m2InputShow'] = this.m2InputShow;
  storageMap['m1Correct'] = this.m1Correct;
  storageMap['m2Correct'] = this.m2Correct;
  storageMap['m1CorrectValue'] = this.m1CorrectValue;
  storageMap['m2CorrectValue'] = this.m2CorrectValue;
  storageMap['correctModal'] = this.correctModal;
  storageMap['loseModal'] = this.loseModal;
  storageMap['inputSectionShow'] = this.inputSectionShow;
  storageMap['prevShow'] = this.prevShow;
  storageMap['nextShow'] = this.nextShow;
  storageMap['badgeL1'] = this.badgeL1;
  storageMap['badgeL2'] = this.badgeL2;
  storageMap['showTextHints'] = this.showTextHints;
  storageMap['swapHints'] = this.swapHints;
  storageMap['yearHint1'] = this.yearHint1;
  storageMap['directorHint1'] = this.directorHint1;
  storageMap['actorHint1_0'] = this.actorHint1_0;
  storageMap['actorHint1_1'] = this.actorHint1_1;
  storageMap['actorHint1_2'] = this.actorHint1_2;
  storageMap['actorHint1Num'] = this.actorHint1Num;
  storageMap['yearHint2'] = this.yearHint2;
  storageMap['directorHint2'] = this.directorHint2;
  storageMap['actorHint2_0'] = this.actorHint2_0;
  storageMap['actorHint2_1'] = this.actorHint2_1;
  storageMap['actorHint2_2'] = this.actorHint2_2;
  storageMap['actorHint2Num'] = this.actorHint2Num;
  storageMap['answeredMovieIdx'] = this.answeredMovieIdx;
  storageMap['m1Guesses'] = this.m1Guesses
  storageMap['m2Guesses'] = this.m2Guesses;
  storageMap['movie1Input'] = this.movie1Input;
  storageMap['movie2Input'] = this.movie2Input;
  storageMap['show1'] = this.show1;
  storageMap['show2'] = this.show2;
  storageMap['show3'] = this.show3;
  storageMap['show4'] = this.show4;
  storageMap['show5'] = this.show5;
  storageMap['show6'] = this.show6;
  storageMap['show7'] = this.show7;
  window.localStorage.setItem(key, JSON.stringify(storageMap));
  } catch(e){}
  },

  fromStorage() {
  try {
  let key = 'cinemashle'+this.mashNum;
  if (window.localStorage.getItem(key) == null) {
  return;
  }
  let storageMap = JSON.parse(window.localStorage.getItem(key));
  this.currGuess = storageMap['currGuess'];
  this.guessesRemaining = storageMap['guessesRemaining'];
  this.mashNum = storageMap['mashNum'];
  this.m1InputShow = storageMap['m1InputShow'];
  this.m2InputShow = storageMap['m2InputShow'];
  this.m1Correct = storageMap['m1Correct'];
  this.m2Correct = storageMap['m2Correct'];
  this.m1CorrectValue = storageMap['m1CorrectValue'];
  this.m2CorrectValue = storageMap['m2CorrectValue'];
  this.correctModal = storageMap['correctModal'];
  this.loseModal = storageMap['loseModal'];
  this.inputSectionShow = storageMap['inputSectionShow'];
  this.prevShow = storageMap['prevShow'];
  this.nextShow = storageMap['nextShow'];
  this.badgeL1 = storageMap['badgeL1'];
  this.badgeL2 = storageMap['badgeL2'];
  this.showTextHints= storageMap['showTextHints'];
  this.swapHints= storageMap['swapHints'];
  this.yearHint1= storageMap['yearHint1'];
  this.directorHint1= storageMap['directorHint1'];
  this.actorHint1_0= storageMap['actorHint1_0'];
  this.actorHint1_1= storageMap['actorHint1_1'];
  this.actorHint1_2= storageMap['actorHint1_2'];
  this.actorHint1Num= storageMap['actorHint1Num'];
  this.yearHint2= storageMap['yearHint2'];
  this.directorHint2= storageMap['directorHint2'];
  this.actorHint2_0= storageMap['actorHint2_0'];
  this.actorHint2_1= storageMap['actorHint2_1'];
  this.actorHint2_2= storageMap['actorHint2_2'];
  this.actorHint2Num= storageMap['actorHint2Num'];
  this.answeredMovieIdx = storageMap['answeredMovieIdx'];
  this.m1Guesses = storageMap['m1Guesses'];
  this.m2Guesses = storageMap['m2Guesses'];
  this.movie1Input = storageMap['movie1Input'];
  this.movie2Input = storageMap['movie2Input'];
  this.show1 = storageMap['show1'];
  this.show2 = storageMap['show2'];
  this.show3 = storageMap['show3'];
  this.show4 = storageMap['show4'];
  this.show5 = storageMap['show5'];
  this.show6 = storageMap['show6'];
  this.show7 = storageMap['show7'];
  } catch(e) {}
  },

  updateGlobalMashProgress(mashNum, progress) {
  try {
  let storageMap = {};
  let key = 'mashleProgress';
  if (window.localStorage.getItem(key) !== null) {
  storageMap = JSON.parse(window.localStorage.getItem(key));
  }
  storageMap[mashNum] = progress;
  window.localStorage.setItem(key, JSON.stringify(storageMap));
  } catch(e){}
  this.mashProgress[mashNum] = progress;
  },

  getGlobalMashProgress() {
  let storageMap = {};
  try {

  let key = 'mashleProgress';
  if (window.localStorage.getItem(key) !== null) {
  storageMap = JSON.parse(window.localStorage.getItem(key));
  }
  } catch(e){}
  return storageMap;
  },

  displayAbout() {
  this.showAbout = true;
  this.mailgen = 'mailto:'+this.mailA+'@'+this.mtdomain;
  },

  hideAbout() {
  this.showAbout = false;
  },
  hideScript() {
  this.movieScript = false;
  this.scriptPath();
  },
  showScript() {
  this.movieScript = true;
  this.scriptPath();
  },

  disp1() {
  this.show1 = true;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;
  this.prevShow = false;
  if(this.currGuess > 1 || this.correctModal) {
  this.nextShow = true;
  }
  },

  disp2() {
  if(this.currGuess >= 2  || this.correctModal) {
  this.show1 = false;
  this.show2 = true;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;
  this.prevShow = true;
  if(this.currGuess > 2 || this.correctModal) {
  this.nextShow = true;
  }
  }
  },
  disp3() {
  if(this.currGuess >= 3 || this.correctModal) {
  this.show1 = false;
  this.show2 = false;
  this.show3 = true;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;
  this.prevShow = true;
  if(this.currGuess > 3 || this.correctModal) {
  this.nextShow = true;
  }
  }
  },
  disp4() {
  if(this.currGuess >= 4 || this.correctModal) {
  this.show1 = false;
  this.show2 = false;
  this.show3 = false;
  this.show4 = true;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;
  this.prevShow = true;
  if(this.currGuess > 4 || this.correctModal) {
  this.nextShow = true;
  }
  }
  },
  disp5() {
  if(this.currGuess >= 5 || this.correctModal) {
  this.show1 = false;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = true;
  this.show6 = false;
  this.show7 = false;
  this.prevShow = true;
  if((this.currGuess > 5 || this.correctModal) && this.daily['ic'+this.mashNum] >=6) {
  this.nextShow = true;
  } else {
  this.nextShow = false;
  }
  }
  },

  disp6() {
  if((this.currGuess >= 6 || this.correctModal) && this.daily['ic'+this.mashNum] >=6) {
  this.show1 = false;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = true;
  this.show7 = false;
  this.prevShow = true;
  if((this.currGuess > 6 || this.correctModal) && this.daily['ic'+this.mashNum] >=7) {
  this.nextShow = true;
  }else {
  this.nextShow = false;
  }
  }
  },


  disp7() {
  if((this.currGuess >= 7 || this.correctModal)&& this.daily['ic'+this.mashNum] >=7) {
  this.show1 = false;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = true;
  this.prevShow = true;
  this.nextShow = false;
  }
  },


  nextSlide() {
  if(this.show1===true) {
  this.disp2();
  } else if(this.show2===true) {
  this.disp3();
  } else if(this.show3===true) {
  this.disp4();
  } else if(this.show4===true) {
  this.disp5();
  } else if(this.show5===true && this.daily['ic'+this.mashNum] >=6) {
  this.disp6();
  } else if(this.show6===true && this.daily['ic'+this.mashNum] >=7) {
  this.disp7();
  }
  },

  prevSlide() {

  if(this.show7===true) {
  this.disp6();
  } else if(this.show6===true) {
  this.disp5();
  } else if(this.show5===true) {
  this.disp4();
  } else if(this.show4===true) {
  this.disp3();
  } else if(this.show3===true) {
  this.disp2();
  } else if(this.show2===true) {
  this.disp1();
  }
  },
  clearIn1() {
  if(this.movie1Input) {
  this.movie1Input = "";
  }
  },
  clearIn2() {
  if(this.movie2Input) {
  this.movie2Input = "";
  }
  },
  onCorrect() {
  this.correctModal = true;
  this.inputSectionShow = false;
  switch(this.daily['ic'+this.mashNum]) {
  case 5:
  this.disp5();
  break;
  case 6:
  this.disp6();
  break;
  case 7:
  this.disp7();
  break;
  default:
  this.disp1();
  break;
  }
  this.showScript();
  },

  onLose() {
  this.loseModal = true;
  this.inputSectionShow = false;
  this.scriptPath();
  },

  showNextClue() {
  this.currGuess = this.currGuess+1;
  if(this.currGuess == 2) {
  this.disp2();
  } else if(this.currGuess == 3) {
  this.disp3();
  }else if(this.currGuess == 4) {
  this.disp4();
  }else if(this.currGuess == 5) {
  this.disp5();
  } else if(this.currGuess == 6 && this.daily['ic'+this.mashNum] >=6) {
  this.disp6();
  } else if(this.currGuess == 7 && this.daily['ic'+this.mashNum] >=7) {
  this.disp7();
  }
  this.calcHintShows();

  },

  calcHintShows() {
  this.scriptPath();
  let shownCount = 2;
  shownCount -= this.directorHint1 != false ? 1 : 0;
  shownCount -= this.directorHint2 != false ? 1 : 0;
  shownCount -= this.answeredMovieIdx == 0 ? 1 : 0;
  shownCount -= this.answeredMovieIdx == 1 ? 1 : 0;

  if(shownCount > 0 && false) { // DISABLING BUTTONS
this.showDirectorHintButton = false;
} else {
this.showDirectorHintButton = false;
}

shownCount = 2;
shownCount -= this.yearHint1 != false ? 1 : 0;
shownCount -= this.yearHint2 != false ? 1 : 0;
shownCount -= this.answeredMovieIdx == 0 ? 1 : 0;
shownCount -= this.answeredMovieIdx == 1 ? 1 : 0;

if(shownCount > 0 && false) { // DISABLING BUTTONS
  this.showYearHintButton = true;
  } else {
  this.showYearHintButton = false;
  }

  let availActorHints = 6;
  //console.log(" ami: "+this.answeredMovieIdx);
  availActorHints-= this.answeredMovieIdx == 0 ? 3 : this.actorHint1Num;
  //console.log("avail hintA "+availActorHints);
  availActorHints-= this.answeredMovieIdx == 1 ? 3 : this.actorHint2Num;
  //console.log("avail hintB "+availActorHints);

  if(availActorHints > 0 && false) { // DISABLING BUTTONS
  this.showActorHintButton = true;
  } else {
  this.showActorHintButton = false;
  }

  if(this.currGuess > 5 && (this.showYearHintButton || this.showDirectorHintButton || this.showActorHintButton)) {
  //this.showTextHints = true;
  // DISABLING BUTTONS
  this.showTextHints = false;
  } else {
  this.showTextHints = false;
  }

  },

  cleanAnswer(a) {
  if(a.trim().length == 0) {
  return 'Skipped';
  }
  return a;
  },

  copyBadge() {
  let textBadge = 'Cinemashle #'+this.mashNum+'\n';
  textBadge+=this.badgeL1+'\n';
  textBadge+=this.badgeL2+'\n';
  textBadge+='cinemashle.com';
  navigator.clipboard.writeText(textBadge);
  this.shareText = 'COPIED';
  },

  canCopy() {
  return typeof (navigator.clipboard) !== 'undefined';
  },

  earlierMashExists() {
  return this.mashNum > 1;
  },

  dispYearHint(movieNum) {
  let displaySide = movieNum+1;
  if(this.swapHints) {
  displaySide = movieNum == 0 ? 2 : 1;
  }

  this['yearHint'+displaySide] = this.daily[this.mashNum][movieNum]['year'];
  this.showTextHints = false;
  this.toStorage();
  this.guessMetricsA = this.mashNum+'/y'+displaySide+'.gif';
  },

  showYearHint() {
  let movieNum = this.getRandomInt(2);
  if(this.answeredMovieIdx == 0 || this.yearHint1 ) {
  movieNum = 1;
  } else if(this.answeredMovieIdx == 1 || this.yearHint2) {
  movieNum = 0;
  }

  this.dispYearHint(movieNum);
  },

  dispDirectorHint(movieNum) {
  let displaySide = movieNum+1;
  if(this.swapHints) {
  displaySide = movieNum == 0 ? 2 : 1;
  }

  this['directorHint'+displaySide] = this.daily[this.mashNum][movieNum]['director'];
  this.showTextHints = false;
  this.toStorage();
  this.guessMetricsA = this.mashNum+'/d'+displaySide+'.gif';
  },

  showDirectorHint() {
  let movieNum = this.getRandomInt(2);
  if(this.answeredMovieIdx == 0 || this.directorHint1 ) {
  movieNum = 1;
  } else if(this.answeredMovieIdx == 1 || this.directorHint2) {
  movieNum = 0;
  }

  this.dispDirectorHint(movieNum);
  },

  dispActorHint(movieNum) {
  let actorHintMovieNum = movieNum+1;
  if(this['actorHint'+actorHintMovieNum+'Num'] >=3) {
  return;
  }
  let displaySide = actorHintMovieNum;
  if(this.swapHints) {
  displaySide = displaySide == 1 ? 2 : 1;
  }
  this['actorHint'+displaySide+'_'+this['actorHint'+actorHintMovieNum+'Num']] = this.daily[this.mashNum][movieNum]['billing'][this['actorHint'+actorHintMovieNum+'Num']];
  this['actorHint'+actorHintMovieNum+'Num']++;
  this.showTextHints = false;
  this.toStorage();
  this.guessMetricsA = this.mashNum+'/a'+displaySide+'_'+actorHintMovieNum+'.gif';
  },

  showActorHint() {
  let movieNum = this.getRandomInt(2);
  if(this.answeredMovieIdx == 0 || this.actorHint1Num >= 3) {
  movieNum = 1;
  } else if(this.answeredMovieIdx == 1 || this.actorHint2Num >= 3) {
  movieNum = 0;
  }
  if(this.answeredMovieIdx === movieNum) {
  return; // must be that all are shown
  }

  let actorHintMovieNum = movieNum+1;

  if(this['actorHint'+actorHintMovieNum+'Num'] >=3) {
  return; // must be that all 6 actors have been shown
  }

  //console.log('putting '+actorHintMovieNum+' from '+this['actorHint'+actorHintMovieNum+'Num']+' to '+'actorHint'+displaySide+'_'+this['actorHint'+actorHintMovieNum+'Num']);
  this.dispActorHint(movieNum);
  },

  swap(arr, key1, key2) {
  let tmp = arr[key1];
  arr[key1] = arr[key2];
  arr[key2] = tmp;
  //console.log('tmp '+tmp);
  },

  // If the user answered on the side
  swapHintSides() {
  //console.log('switching sides '+this.answeredMovieIdx);
  /*let fromSide = 1;
  let toSide = 2;
  if(this.answeredMovieIdx == 0 ) {
  fromSide = 2;
  toSide = 1;
  }*/
  let tmp = '';
  this.swap(this,'yearHint1','yearHint2');
  this.swap(this,'directorHint1','directorHint2');
  this.swap(this,'actorHint1_0','actorHint2_0');
  this.swap(this,'actorHint1_1','actorHint2_1');
  this.swap(this,'actorHint1_2','actorHint2_2');
  /*this['yearHint'+fromSide] = this['yearHint'+toSide];
  this['directorHint'+fromSide] = this['directorHint'+toSide];
  this['actorHint'+fromSide+'_0'] = this['actorHint'+toSide+'_0'];
  this['actorHint'+fromSide+'_1'] = this['actorHint'+toSide+'_1'];
  this['actorHint'+fromSide+'_2'] = this['actorHint'+toSide+'_2'];*/
  },

  answerMatches(input, compareToMovieIdx) {
  let inName = input.toLowerCase();
  if(this.daily[this.mashNum][compareToMovieIdx]['movie'].toLowerCase() == inName) {
  return true;
  }
  let i=0;
  while(i < this.daily[this.mashNum][compareToMovieIdx]['alternateNames'].length) {
      if(this.daily[this.mashNum][compareToMovieIdx]['alternateNames'][i].toLowerCase() == inName) {
    return true;
    }
    i++;
    }
  },
  
  answerL() {
    try {
  this.guessMetricsA = this.mashNum+'/'+this.currGuess+'.gif?a='+this.movie1Input;
  this.guessMetricsB = this.mashNum+'/'+this.currGuess+'.gif?a='+this.movie2Input;
  } catch(e) {}
  },

  checkAnswers () {
  this.movie1Input = this.movie1Input.trim();
  this.movie2Input = this.movie2Input.trim();
  
  if(this.movie1Input && this.movie1Input.trim().length > 0 && this.movie1Input === this.movie2Input) {
    return; //todo: Notify player
    }
    this.answerL();

    if(this.currGuess == 3) {
    this.dispYearHint(0);
    this.dispYearHint(1);
    }  else if (this.currGuess >= 4){
    this.dispActorHint(0);
    this.dispActorHint(1);
    }
   // else if (this.currGuess == 4){
    //this.dispDirectorHint(0);
    //this.dispDirectorHint(1);
    //}

    this.guessesRemaining--;
    this.updateGlobalMashProgress(this.mashNum, 'p');
    let answerTmp = false;

    if(this.m1Correct) {
    this.m1Guesses.push(this.m1Guesses[this.m1Guesses.length-1]);
    this.badgeL1+=this.black;
    } else {
    answerTmp = this.answerMatches(this.movie1Input, 0) ? this.daily[this.mashNum][0]['movie'] : answerTmp;
    answerTmp = (!answerTmp && this.answerMatches(this.movie1Input, 1)) ? this.daily[this.mashNum][1]['movie'] : answerTmp;
    }

    if(this.answerMatches(this.movie1Input, 0) || this.answerMatches(this.movie2Input, 0)) {
    this.answeredMovieIdx = 0;
    }

    if(this.answerMatches(this.movie1Input, 1) || this.answerMatches(this.movie2Input, 1)) {
    this.answeredMovieIdx = 1;
    }

    if(!this.m1Correct && answerTmp) {
    this.m1InputShow = false;
    this.m1Correct = true;
    this.m1CorrectValue = answerTmp;
    this.m1Guesses.push(this.green+' '+answerTmp);
    this.guessMetricsAnswerA = this.mashNum+'/c1_'+this.currGuess+'.gif';
    this.updateGlobalMashProgress(this.mashNum, 'c');
    this.badgeL1+=this.green;
    this.swapHints = this.swapHints || this.answerMatches(this.movie1Input, 1);
    if(this.swapHints) {
    this.swapHintSides();
    }
    } else if(!this.m1Correct){
    this.m1Guesses.push(this.red+' '+this.cleanAnswer(this.movie1Input));
    this.movie1Input = "";
    this.badgeL1+=this.red;
    }

    answerTmp = false;
    if(this.m2Correct) {
    this.m2Guesses.push(this.m2Guesses[this.m2Guesses.length-1]);
    this.badgeL2+=this.black;
    } else {
    answerTmp = this.answerMatches(this.movie2Input, 0) ? this.daily[this.mashNum][0]['movie'] : answerTmp;
    answerTmp = (!answerTmp && this.answerMatches(this.movie2Input, 1)) ? this.daily[this.mashNum][1]['movie'] : answerTmp;
    }

    if(!this.m2Correct && answerTmp) {
    this.m2InputShow = false;
    this.m2Correct = true;
    this.m2CorrectValue = answerTmp;
    this.m2Guesses.push(this.green+' '+answerTmp);
    this.guessMetricsAnswerB = this.mashNum+'/c2_'+this.currGuess+'.gif';
    this.updateGlobalMashProgress(this.mashNum, 'c');
    this.badgeL2+=this.green;
    this.swapHints = this.swapHints || this.answerMatches(this.movie2Input, 0);
    if(this.swapHints) {
    this.swapHintSides();
    }
    } else if(!this.m2Correct){
    this.m2Guesses.push(this.red+' '+this.cleanAnswer(this.movie2Input));
    this.movie2Input = "";
    this.badgeL2+=this.red;
    }

    if(this.m2Guesses.length > 4 && false) { // DISABLING BUTTONS
    this.showTextHints = true;
    }
    this.scriptPath();
    if(this.m1Correct && this.m2Correct) {
    this.onCorrect();
    } else  if(this.guessesRemaining > 0) {
    this.showNextClue();
    } else {
    this.onLose();
    this.updateGlobalMashProgress(this.mashNum, 'c');
    }
    this.toStorage();
    }

    }

    }


  </script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
  div {
  background-color:black;
  font-weight: 400;
  color: #cccccc;
  }
  .imgbox {
  height: 100%;
  }
  .topBar {
  display: grid;
  grid-template-columns: auto auto auto auto;
  }
  .center-fit {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
  }
  topFrame {
  width: 100%;
  height: 100%;
  }
  .slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
  }
  .mySlides {
  display: none;
  }
  .prev, .next {
  cursor: pointer;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  }
  .next {
  right: 0;
  border-radius: 3px 0 0 3px;
  }
  .prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
  }
  .numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  }

  /* The dots/bullets/indicators */
  .dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  }

  .active, .dot:hover {
  background-color: #717171;
  }

  /* Fading animation */
  .fade {
  animation-name: fade;
  animation-duration: 1.5s;
  }

  @keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
  }
  .inputSection {
  display: inline-block;
  }
  .m1Input {
  float:left;
  margin-right: 5px
  }
  .m2Input {
  overflow: hidden;
  margin-left: 5px
  }
  .alternativeh3 {
  font-size: 18pt;
  }
  .alternativeh4 {
  font-size: 16pt;
  }
  .checkAnswers {
  margin-top: 3px;
  }
  .guesses {
  margin-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  border: 0px 0px 1px 1px;
  padding: 4px 4px 4px 4px;
  border-color: #444444;
  border-style: solid;
  border-width: thin;
  }
  .correctModal {
  }
  .shareButton {
  margin-top: 5px;
  color: #ffffff;
  background-color: #026526;
  font-size: 20px;
  border: 0;
  font-weight: bold;
  box-shadow: none;
  border-radius: 0px;
  }
  .regularButton {
  border: 0;
  color: #dddddd;
  background-color: #4d6440;
  font-size: 18px;
  box-shadow: none;
  border-radius: 0px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  }

  .dropbtn {
  color: #111111;
  background-color: #dddddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
  }

  .dropdown {
  position: relative;
  display: inline-block;
  }

  .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  }

  .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 80px;
  margin-left: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  }

  .dropdown:hover .dropdown-content {
  display: block;
  }

  .dropdown-content a:hover {background-color: #c1c1c1}

  .dropdown:hover .dropbtn {
  background-color: #444444;
  }

  .about {
  position: fixed;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  text-align: left;
  z-index: 1;
  border: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #dddddd;
  }
  .aboutText {
  margin-bottom: 10px;
  margin-left:3px;
  margin-right: 3px;
  }
  .mashNumInset {
  position: absolute;
  top: 0px;
  left: 12%;
  background-color: transparent;
  text-align: left;
  text-shadow: -1px 1px #000000;
  }
  .tutorial {
  display: inline-block;
  text-align: center;
  width: 50%;
  margin-bottom: 10px;
  }
</style>
